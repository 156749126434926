.popup-list {
  margin-left: 10px;
  li {
    position: relative;
  }

  li::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -9px;
    width: 3px;
    height: 3px;
    background-color: #000;
    border-radius: 100%;
  }
}
