.add-tenant {
  padding: 186px 24px 24px 24px;

  .breadcrumbs {
    background-color: #fff;
    position: fixed;
    z-index: 5;
    top: 63px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #f0f2f5;
  }
}

div.tenant-general-card {
  border: 1px solid #d9d9d9;
}

.tenant-descr > div {
  display: flex;
  align-items: center;
}
