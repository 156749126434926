div.tenant {
  padding: 194px 24px 24px 24px;

  .breadcrumbs {
    background-color: #fff;
    position: fixed;
    z-index: 5;
    top: 63px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #f0f2f5;
  }
}

.cancel-btn {
  margin-right: 8px;
}
