@import "./core/normalize.scss";
@import "./core/fonts.scss";
@import "./core/variables.scss";

:root {
  .notification-wrapper {
    .ant-modal-confirm-btns {
      margin-top: 0;
    }
  }
}

body {
  font-family: "Poppins";
}

ul {
  list-style: none;
}

#root {
  height: 100%;
  background-color: #f0f2f5;
}

.app {
  height: 100%;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
