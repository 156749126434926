.policy-table {
  margin: 24px;

  & table > thead > tr th {
    &::before {
      display: none;
    }
  }

  .pt-statement-number {
    text-align: right;
  }

  .pt-statement-effect {
    position: relative;
    padding-left: 8px;

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
      border-radius: 100%;
    }
  }

  div.condition-table {
    & table > thead > tr th {
      background-color: #f0f5ff;
      border-bottom: none;

      &::before {
        display: none;
      }
    }
  }

  .effect-allow {
    &::before {
      background-color: #52c41a;
    }
  }

  .effect-deny {
    &::before {
      background-color: #ff4d4f;
    }
  }
}
