.policies {
  .policy-card {
    margin: 20px;
    border: 1px solid #d9d9d9;
    .segmented-body {
      background-color: #fff;
      padding: 14px 26px;
      box-shadow: inset 0px -1px 0px #f0f0f0;
    }
    .copy-button {
      margin-left: 8px;
    }
  }

  .descriptions {
    .ant-descriptions-view {
      table {
        width: auto;
      }
    }
  }
}

.policyList {
  margin-left: 15px;
}

.row {
  vertical-align: top;
}

.popup-question {
  display: flex;
  align-items: center;

  .anticon {
    width: 18px;
    height: 18px;
    margin-left: 0;

    svg {
      width: 18px;
      height: 18px;
      color: #fff;
      background-color: #faad14;
      border-radius: 100%;
    }
  }

  span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;

  .popup-btn {
    margin-right: 8px;
  }
}
