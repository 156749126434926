.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;

  .header-right {
    display: flex;
    align-items: center;
    position: relative;
  }

  .header-left {
    display: flex;
    align-items: center;

    a {
      font-weight: 400;
    }
  }

  .ant-menu-horizontal {
    width: 700px;
    border-bottom: none;
  }

  .ant-menu-item {
    line-height: 63px;
    font-size: 16px;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 0 0 0 33px;
  }

  .ant-menu-horizontal > .ant-menu-item::after {
    left: 33px;
    right: 0;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    left: 33px;
    right: 0;
  }

  .ant-menu-item .anticon + span {
    margin-left: 12px;
  }

  a.logo {
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(91.78deg, #52c41a 2.22%, #1493da 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.header-auth {
  height: 63px;
}

.logout-icon {
  margin-right: 8px;

  svg {
    width: 12px;
    height: 12px;
  }
}

.header-account {
  text-align: center;
  padding: 0 24px;
  color: #1890ff;
  cursor: pointer;
}

.version-modal {
  .ant-modal-footer {
    padding: 0;
  }
}

.header-support-item {
  color: #000;

  &:hover {
    color: #000;
  }
}

.header-support-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-right: 16px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 100%;

  .header-support-button {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      width: 16px;
      height: 18px;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

li.header-logout {
  padding: 0;
}

.header-logout-btn {
  padding: 5px 12px;
}

button.okButton {
  display: none;
}

button.cancelButton {
  display: none;
}
